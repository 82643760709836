.advertisement-table-content-div {
  height: fit;
  min-height: 40vh;
  max-height: 80vh;
  width: 100%;
  display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  padding: 20px;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: auto;
  flex-direction: column;
  margin-top: 20px;
  // border-radius: 20px;
  // position: relative;
}
.advertisement-content-div {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 10px; /* Gap between items */
  flex-direction: column;
}
.ad-image-style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}
.ad-col-3 {
  gap: 10px;
  position: relative;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 200px;
  width: 100%;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #cccccc;
  border-radius: 15px;
}
.advertisement-spiner-div {
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ad-desc {
  width: 100%;
  height: 60px;
  z-index: 99;
  background: linear-gradient(
    0deg,
    rgba(7, 10, 11, 0.9) 10.7%,
    rgba(0, 0, 0, 0.75) 34.57%,
    rgba(7, 10, 11, 0) 88.57%
  );
  // background: linear-gradient(180deg, rgba(87, 89, 91, 0.3) 7.97%, #000 100%);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}
.ad-col-4 {
  // min-height: 162px;
  height: fit-content;
  width: 45%;

  border-radius: 20px;
}
.local-ad-paragraph {
  text-align: initial;
  // overflow-y: scroll;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'InterNormal';
}
.local-ad-row-2 {
  color: #ffffff;
  justify-content: space-between;
  font-size: 18px;
  font-style: bold;
  font-weight: 600;
  line-height: normal;
  font-family: 'InterNormal';
}
.ad-title {
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.ad-time {
  margin-top: 10px;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;

  color: #ffffff;
  text-align: start;
}
.ad-buisness-row-1 {
  font-weight: 400;
  margin-bottom: 20px;
}
.ad-image-style-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
}
