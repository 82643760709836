.outlet-container {
  background: var(--GENERIC_BG_BACKGROUND);
  width: 80vw;
  height: 100vh;
  padding: 20px;
}
.outlet-container-1 {
  background: white;
  width: 80vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem ;
  justify-content: center;
  align-items: center;
  padding-right: 200px;
}


