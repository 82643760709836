.facility-add-button {
  width: 8.188rem !important;
}

.active-facility-switch {
  background: #d9d9d9;
  .ant-switch-handle::before {
    background-color: #080ccb;
  }
}
.inactive-facility-switch {
  background: #d9d9d9;
  .ant-switch-handle::before {
    background-color: #718096;
  }
}
.facility-add-button-row {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.facility-pagination {
  .ant-pagination {
    margin-top: 10px;
  }
}

.facility-pagination-div {
  // height: 40px;
  width: 97%;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;
  bottom: 10px;
  margin-top: 50px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  // box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}
.preview-style {
  margin-top: 10px;
  .ant-btn-primary {
    color: #fff;
    border-color: rgb(8, 12, 203);
    background: rgb(8, 12, 203);
    border-radius: 20px;
    height: 30px;
    // text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    // box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
}
.preview-style-1 {
  margin-top: -7px;
  margin-bottom: 8px;
  .ant-btn-primary {
    color: #fff;
    border-color: rgb(8, 12, 203);
    background: rgb(8, 12, 203);
    border-radius: 20px;
    height: 30px;
    // text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    // box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
}
.ant-layout {
  overflow-y: auto !important;
}
.facility-demo-tag {
  display: inline-block;
  padding: 2px 8px;
  background-color: orange;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  text-transform: uppercase;
}
