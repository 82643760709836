.diningPage.row-col {
  width: 100%;
  gap: 0;
}
.diningDetails .local-buisness-table-content-div {
  min-height: 65vh !important;
  max-height: 65vh !important;
  background-color: transparent;
  padding-top: 2rem;
  padding-left: 0;
  padding-right: 0;
}
.restaurantPage .marquee-container,
.diningDetails .marquee-container {
  width: 100% !important;
}
.diningPage .spinner {
  padding-top: 0;
  margin-top: 0;
  min-height: 65vh;
  margin-top: auto;
  margin-bottom: auto;
}
.restaurant-card {
  display: flex;
  flex-direction: column;
  height: 270px;
  width: 100%;
  background: #e2e8f0;
  border-radius: 16px;
}
.restaurant-image-div,
.restaurant-noImage-div {
  height: 147px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #bdcde3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.restaurant-image-div {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
}
.restaurant-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
}
.restaurant-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 12px;
}
.restaurant-name-div {
  height: 20px;
  overflow-y: scroll;
}
.restaurant-name-div::-webkit-scrollbar {
  display: none;
}
.restaurant-name-div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.restaurant-name {
  font-weight: 700;
  color: #09101d;
  width: 100%;
}
.restaurant-time-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}
.restaurant-time {
  width: 100%;
  font-weight: 400;
  color: #718096;
  text-align: start;
}
.view-dining-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 50px;
  border: 1px solid #080ccb;
  height: 31px;
  padding: 8px 16px;
  width: 100%;
}
.view-dining-button-text {
  font-weight: 600;
  color: #080ccb;
}

.image-coloumn {
  width: 100%;
  height: 100%;
  border-radius: 40px;
}
.restuarent-div {
  background: #080ccb;
  height: 98px;
  border-radius: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 11px;
  width: 7vw;
  margin: 15px;
}
.col-2 {
  margin-top: 19px;
}
.row-col {
  display: flex;
  gap: 18px;
}
.col-2-font-style {
  color: #718096;
  font-weight: 600;
}
.col-2-font-style-href {
  color: #080ccb;

  font-weight: 600;
  cursor: pointer;
}
.col-2-font-style-href:hover {
  opacity: 0.6;
  transition: 0.5s;
}
.col-2-heading {
  font-weight: 700;
  font-size: 13px;
  color: #09101d;
}

// meal data modal
.mealData-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mealData-modal .ant-modal-content {
  min-width: 500px;
}
.mealData-modal-div {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}
.mealData-modal-div .restImage-noImage-div {
  min-width: 65px;
  // height: unset;
}
.modal-restaurent {
  margin-bottom: 10px;
}
.modal-restaurent-col-1 {
  gap: 9px;
  color: #080ccb;
  font-size: 16px;
}
.modal-restaurent-row-2 {
  color: #718096;
  font-size: 12px;
  font-weight: 600;
}
.gap {
  gap: 10px;
}
.no-item {
  color: gray;
}
.no-meals-div {
  margin-top: 10px;
}
.short-title {
  height: 10px;
  margin-top: 5px;
  width: 70%;
  border-radius: 3px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}
.short-title-1 {
  height: 280px;
  margin-top: 5px;
  width: 20px;
  border-radius: 3px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}
.short-title-2 {
  height: 180px;
  margin-top: 5px;
  width: 20px;
  border-radius: 3px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}
.short-title-3 {
  height: 80px;
  margin-top: 5px;
  width: 20px;
  border-radius: 3px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}
@keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.div-2-row {
  width: 61%;
  margin-top: 10px;
}
.dining-schedule-overflow {
  height: 65px;
  overflow-y: auto;
}
.meal-category {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
}
.category-name {
  margin-bottom: 1rem;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  width: 100%;
}
