.facility-details-container {
  height: 80%;
  width: 100%;
}
.facility-details-heading-row {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.facility-details-heading-back-col {
  display: flex;
  justify-content: initial;
  align-items: center;
}
.facility-details-heading-button-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.facility-details-header-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #080ccb;
  margin-left: 20px;
}
.facility-details-row {
  width: 100%;
  height: auto;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-13 {
  margin-top: 13px;
}
.facility-details-title-field {
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  text-align: start;
  color: #09101d;
}
.facility-details-title-description {
  font-weight: 500;
  line-height: 20px;
  color: #718096;
  text-align: initial;
}

.facility-details-tab {
  margin-top: 2.125rem;
  width: 100%;
  // height: fit-content;
  // max-height: 80vh;
  // min-height: 50vh;
  height: 60vh;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  padding: 1.25rem;
  padding-top: 0.5rem;
  // overflow-y: auto;
}
.facility-details-tab {
  width: 100%;
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: inline-block;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid #f4f4f4;
    transform: translate(0);
  }
}
.deals {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
.facility-image-style {
  width: 175px;
  height: 132px;
  border-radius: 15px;
  object-fit: cover;
}
.facility-img-col {
  display: flex;
  justify-content: flex-end;
}
.facility-img-row {
  justify-content: flex-end;
}
.facility-details-disable-feat-div {
  padding-top: 1rem;
}
.facility-details-container .ant-tabs-content-holder {
  overflow-y: auto;
}
.approve-switch-container {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
}

.approve-switch {
  transition: all 0.3s ease;
}
.facility-demo-tag {
  display: inline-block;
  margin-left: 10px;
  padding: 2px 8px;
  background-color: orange;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  text-transform: uppercase;
}
