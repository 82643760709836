// .ant-layout-sider {
//   height: 100vh;
// }
.sider-icons {
  width: 30px;
}
.sider-container {
  height: 100vh;
  width: 12.5rem;
  background-color: #ffffff;
  overflow-y: auto;
  .sider-container-inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sider-btn-container {
    height: fit-content;
    width: 100%;
  }
  .awaytogether-logo-sider {
    margin: 2rem 1.25rem;
  }
  .menu-inactive-item {
    background-color: #ffffff;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: var(--GENERIC_SIDER_COLOR);
    height: 3.375rem;
    border-bottom: 1px solid #f4f4f4;
    width: 10rem;
    margin: 0 1.25rem;
    padding-left: 1rem;
    display: flex;
    justify-content: initial;
    align-items: center;
    text-align: initial;
    cursor: pointer;
  }
  .menu-active-item {
    background: var(--GENETRIC_SIDER_THEME_GRADIENT_BG);
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #ffffff;
    height: 3.375rem;
    width: 12.5rem;
    padding-left: 2.25rem;
    display: flex;
    justify-content: initial;
    align-items: center;
    text-align: initial;
    cursor: pointer;
  }
  .menu-inactive-item-logout {
    background-color: #ffffff;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: var(--GENERIC_LOGOUT_TXT_COLOR);
    height: 3.375rem;
    width: 10rem;
    margin: 0 1.25rem;
    padding-left: 1rem;
    display: flex;
    justify-content: initial;
    align-items: center;
    text-align: initial;
    cursor: pointer;
  }
  .sider-menu {
    background-color: #ffffff;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;

    .ant-menu-item-selected {
      height: 3.375rem !important;
      padding-left: 2.75rem !important;
      width: 12.5rem !important;
      color: #ffffff !important;
      margin-left: 0 !important;
      background: var(--GENETRIC_SIDER_THEME_GRADIENT_BG);
    }
    .ant-menu-item {
      color: var(--GENERIC_SIDER_COLOR);
      height: 3.375rem;
      border-bottom: 1px solid #f4f4f4;
      width: 10rem;
      margin: 0 1.25rem;
      padding-left: 1rem;
    }
    .ant-menu-item-only-child {
      //   padding-left: 16px !important;
    }
  }
  .awaytogether-copyright-txt {
    text-align: initial;
    margin-left: 1.25rem;
    margin-bottom: 1.5rem;
    margin-right: 3.5rem;
    width: 6.75rem;
    height: 2.25rem !important;
    // position: fixed;
    // bottom: 0;
    font-weight: 500;
    line-height: 18px;
    color: #c3cbcd;
  }
}
.sider-container-1 {
  height: 100vh;
  width: 12.5rem;
  // background-color: linear-gradient(
  //   180deg,
  //   #dcddff 0%,
  //   rgba(210, 211, 255, 0) 100%
  // )
  background-color: white;
}
