.disabled-feature-parent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.disabled-features-normal-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.normal-checkbox-label {
  font-weight: 400;
  line-height: 20px;
  color: #718096;
}

.toggle-checkbox-label {
  margin-right: 1rem;
}
.toggle-checkbox-label .form-check-input:checked {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.toggle-checkbox-label .form-check-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: unset !important;
}
.toggle-checkbox-label .form-check-label {
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #718096;
}

/* Facility details form */
.facility-details-container .disabled-feature-parent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.facility-details-container .disabled-features-normal-checkbox {
  display: flex;
  flex-wrap: wrap;
}
.facility-details-container .toggle-checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.facility-details-container .form-check-label {
  padding-top: 0.3rem;
}
