.facility-activate-switch {
  width: 110px;
  height: 25px;
  background: #ffffff;
  border-radius: 30px;
  margin-left: 20px;
  margin-right: 20px;
  .switch-background-txt {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    line-height: 12px;
    color: #dddeff;
  }
  .switch-active {
    width: 61px;
    height: 25px;

    background: #080ccb;
    border-radius: 30px;
    font-weight: 600;
    line-height: 12px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .switch-inactive {
    width: 61px;
    height: 25px;

    background: #718096;
    border-radius: 30px;
    font-weight: 600;
    line-height: 12px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
