@font-face {
  font-family: 'RalewayBold';
  src: url('./fonts/Raleway-VariableFont_wght.ttf');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'RalewayNormal';
  src: url('./fonts/Raleway-VariableFont_wght.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'InterNormal';
  font-style: normal;
  src: url('./fonts/Inter-VariableFont_slnt\,wght.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'InterBold';
  font-style: normal;
  src: url('./fonts/Inter-VariableFont_slnt\,wght.ttf');
  font-weight: 700;
}

.raleway-20 {
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
}
.raleway-16 {
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
}
.raleway-12-normal {
  font-family: 'RalewayNormal';
  font-style: normal;
  font-size: 12px;
  font-weight: 700;
}
.raleway-12 {
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 12px;
  font-weight: 700;
}
.raleway-10 {
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 10px;
  font-weight: 700;
}
.raleway-13 {
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 13px;
  font-weight: 700;
}
.raleway-14 {
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
}
.inter-12 {
  font-family: 'InterNormal';
  font-style: normal;
  font-size: 12px;
}
.inter-10 {
  font-family: 'InterNormal';
  font-style: normal;
  font-size: 10px;
}
.inter-12-bold {
  font-family: 'InterBold';
  font-style: normal;
  font-size: 12px;
}
.inter-14 {
  font-family: 'InterNormal';
  font-style: normal;
  font-size: 14px;
}
