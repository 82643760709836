.user-add-button-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.delete-modal-txt {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 20px;
  color: var(--GENERIC_SIDER_COLOR);
}
