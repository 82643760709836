.local-buisness-upload-button{
    .ant-btn.ant-btn-default{
        width: 28.5rem;
        height: 2.9rem;
        border-radius: 32px;
        color: #a0aec0;
        border-color: 
        #E2E8F0;
        font-weight: 200;
        line-height: 15px;
        text-align: start;
        // margin-bottom: 15px;

    }
    width: 28.5rem;
    height: 2.9rem;
    border-radius: 32px;
}
.upload-icon{

    display: flex;
    justify-content: space-between;
    // .ant-btn > .anticon + span, .ant-btn > span + .anticon {
    //     margin-left: 328px;
    // }
}
.select-category{
    .ant-select-single:not(.ant-select-customize-input).ant-select-selector {
        width: 100%;
        height: 2.5rem;
        padding: 0 11px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: 0rem;
        border-color: 1px solid black;
        color: #718096;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #fff;
        border-color: #718096;
        border-radius: 51px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        height: 38px;
        font-weight: 500;
        overflow: auto;
    }
    .ant-select {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #a0aec0;
        border: 1px solid var(--GENERIC_BORDER);
    border-radius: 32px;
        font-size: 12px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-bottom: 15px;

    }
    .ant-form-item-explain-error {
        width: 100%;
        margin-top: -10px !important;
        margin-bottom: 5px;
        color: var(--GENERIC_ERROR_COLOR);
        text-align: initial;
        font-style: normal;
        font-size: 12px;
        font-family: 'RalewayBold';
        src: url(http://localhost:3000/static/media/Raleway-VariableFont_wght.3fefe68….ttf);
        font-style: normal;
        font-weight: 500;
    }
}
textarea.ant-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    padding-top: 22px;
}
.document-name-container{
    max-height: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 12px;
}
.document-name-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);

    background-color: #bebebe;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .document-name-container::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
    height: 6px;
  }
  .document-name-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  