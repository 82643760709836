.avtivity-container {
  height: fit-content;
  width: 100%;
  border-radius: 0px;
  // overflow: auto;
  // height: 472px;
  
}
.activities-container {
  height: fit-content;
  width: 100%;
  border-radius: 0px;
  // overflow: auto;
  height: 472px;
  background: white;
  margin-top: 40px;
  
  margin-left:0px ;
  margin-right: 0px;
  padding: 16px;

}
.activity-loader{
display: flex;
justify-content: center;
align-items: center;
}
.activity-page .ant-page-header {
  padding: 16px 0px !important;
}

.view-all-activity{
  color: #080ccb;
  display: flex;
  width: 100%;
  justify-content: flex-end;
 height: 25px;
 align-items: flex-end;
 cursor: pointer;
}
.avtivity-row {
  width: 100%;
  height: 130px;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  margin-top: 15px;
}
.activity-image-col {
  background-color: #080ccb;
  border-radius: 15px;
  width: 100%;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity-details-heading {
  font-weight: 600;
  line-height: 20px;
  color: #09101d;
}
.activity-details-desc {
  font-weight: 500;
  line-height: 18px;
  height: 78px;
  color: #718096;
  text-align: initial;
  overflow-y: auto;
}
.activity-date-heading {
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c3cbcd;
}
.activity-date-desc {
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;

  color: #718096;
}
.amenity-active {
  width: 60px;
  height: 25px;

  background: rgba(0, 198, 138, 0.2);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: -0.01em;

  color: #00c68a;
}
.amenity-inactive {
  width: 60px;
  height: 25px;

  background: #e6e3e3;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: -0.01em;

  color: #718096;
}
.amenity-image {
  width: 9.375rem;
  height: 6.25rem;
  border-radius: 15px;
}
.amenity-img-no-data {
  width: 9.375rem;
  height: 7.25rem;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a0aec0;
}
.amenity-image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.amenity-details-desc {
  font-weight: 500;
  line-height: 18px;
  height: 40px;
  color: #718096;
  text-align: initial;
  overflow-y: auto;
}
.amenity-drop-down-background-wrap{
  width: 80%;
  height: 100%;
  position: absolute;
  z-index:  10;

}

// ---------activity revamp------------------
.activity-parent {
  height: fit-content;
  width: 100%;
  border-radius: 0px;
  overflow: auto;
}
.activity-card {
  height: 110px;
  background: #e2e8f0;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  padding: 20px 15px;
  position: relative;
}
.activity-image {
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.activity-card-image-R {
  min-width: 60px;
}
.activity-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 7px;
}
.activity-details > span {
  text-align: start;
}
.activity-card-details-R {
  width: 100%;
}
.activity-name {
  font-weight: 700;
  line-height: 15px;
  color: #09101d;
}
.activity-timing-div {
  display: flex;
  font-weight: 500;
  line-height: 15px;
  color: #718096;
  position: relative;
}
.activityCard-navigator-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activityCard-guest-div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-right: 0.5rem;
}
.activityCard-navigator-div .view-details,
.activityCard-navigator-div .guest-numbers {
  color: #080ccb;
}
.activityCard-navigator-div .view-details {
  font-weight: 400;
}
.activityCard-navigator-div .guest-numbers {
  font-weight: 600;
}
.activity-date-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}
