body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  --GENERIC_PRIMARY_THEME_BLUE: #080ccb;
  --GENETRIC_THEME_GRADIENT_BG: linear-gradient(
    180deg,
    #dcddff 0%,
    rgba(210, 211, 255, 0.2) 100%
  );
  --GENETRIC_SIDER_THEME_GRADIENT_BG: linear-gradient(
    90deg,
    #080ccb 0%,
    #9708cb 97.5%
  );
  --GENERIC_SIDER_COLOR: #718096;
  --GENERIC_BORDER: #e2e8f0;
  --GENERIC_TEXT_COLOR_BLACK: #09101d;
  --GENERIC_ERROR_COLOR: #ff4d4f;
  --GENERIC_DESCRIPTION_TXT_COLOR: #718096;
  --GENERIC_LOGOUT_TXT_COLOR: #ff0000;
  --GENERIC_BG_BACKGROUND: linear-gradient(
    180deg,
    #dcddff 0%,
    rgba(210, 211, 255, 0) 100%
  );
  --GENERIC_MODAL_HEADER_BG: #e2e8f0;
  --GENERIC_BUTTON_BG: linear-gradient(90deg, #080ccb 0%, #9808cb 100%);
  --GENERIC_BORDER_COLOR: #f4f4f4;
}
.css-yk16xz-control, .css-2b097c-container, .css-1pahdxg-control{
  border: 1px solid #E2E8F0 !important;
  /* width: 20rem; */
  width: 100%;
  border-radius: 50px !important;
  height: 2.75rem;
  // font-family: 'Inter';
  font-style: normal;
  // font-weight: 100;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
  margin-right: 1rem;
  // margin-bottom: 10px;
}
.css-yk16xz-control::placeholder{
  width: 20rem;
  font-family: 'Inter';
  font-style: normal;
  // font-weight: 100;
  font-size: 10px;
  line-height: 15px;
  color: #A0AEC0;
}
.css-1wa3eu0-placeholder {
  color: #A0AEC0 !important;
  font-family: 'InterNormal';
  font-style: normal;
  font-size: 12px;
  font-weight: 100;
}
.css-2b097c-container{
 border: none !important;
}
.div-geolocation{
  input{
    width: 100px;
  }
}
.css-1g6gooi {
  input{
    width: 100% !important;

  }
}
.ant-select.ant-select-in-form-item {
  width: 100%;
  height: 41px;
}
.cursor{
  cursor: pointer;
}