.local-buisness{
    
    justify-content:  flex-start;
    display: flex;
    font-weight: 700;
    line-height: 18.78px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  color: #080ccb;
     size: 16px;
}
.drop-down-background-wrap{
    width: 80%;
    height: 100vh;
    position: absolute;
    z-index:  10;
}
.no-documents-text{
    color: #718096;
}
.view-documents-row{
    display: flex;
    flex-direction: column;
    text-align: start;
}

.local-buisness-filter{
    width: 13.6rem;
    border: none !important;
    border-radius: 50px;
    background-color: #ffffff !important;
    height: 2.5rem;
    line-height: 15px;
    font-weight: 500;
    box-shadow: 0 0 0 0 !important;
    margin-left: 10px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #fff;
        border: none;
        border-radius: 51px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    border-radius: 51px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.local-buisness-col-1{
        display: flex;
        align-items: center;
        justify-content:flex-end;
    
}
.local-buisness-col-2{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    color: #080ccb;
       size: 16px;
       font-weight: 700;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 2.5rem;
    padding: 0 11px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.local-buisness-table-content-div{
    height: 90%;
    min-height: 89vh;
    max-height: 80vh;
    width: 100%;
    display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    padding: 20px;
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: auto;
    flex-direction: column;
    justify-content: space-between;
    // border-radius: 20px;
// position: relative;
}
.local-buisness-col-3{
    width: 50%;
    border-radius: 0px;
    
}
.local-buisness-col-4{
min-height: 162px;
height: fit-content;
background: #F4F4F4;;
width: 45%;

border-radius: 20px;
}
.local-buisness-card-color{
color:#080CCB ;
display: flex;
width:fit-content;;
font-weight: 500;
}
.local-buisness-image{
    display: flex;
    // align-items: center;
    padding-bottom: 22px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    height: 150x;
    width: 100%;
}
.local-buisness-card-col-2{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 18px;
}
.local-buisness-paragraph{
    text-align: initial;
    // overflow-y: scroll;
    color: #718096;
    height: 72px;
}
.local-buisness-row-1{
    color:
    #718096;
}
.local-buisness-row-2{
    color:
    
#09101D;
font-weight: bolder;
justify-content: space-between;
}
.local-image-style{
    width: 100%;
    height: 132px;
    border-radius: 15px;
    object-fit:cover;
}
.local-buisness-pagination{
    .ant-pagination {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }
}
.spinner{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
}
.pagination{
    width:100%;
    display: flex;
    justify-content: center;
    bottom: 32px;
    // position: absolute;
    align-items: flex-end;
    // bottom: 10px;
    // height: 85px;
}
.paragrapgh{
    width: 100%;
    row-gap: 0px;
    overflow-y: auto;
 height: -webkit-fill-available;

}
.document-text{
    color:#080CCB;
    cursor: pointer;
    font-weight: 600;
  
}
.generic-cancel-button-approved{
    background: var(--GENERIC_BUTTON_BG)!important;
    border-radius: 50px;
    height: 1.813rem;
    border: 0 !important;
    text-transform: uppercase;
    width: 100%;
    line-height: 15px;
    color: #ffffff !important;
    margin-left: 10px;
} 
.loacl-buissness-amenity-img-no-data {
    width: 9.375rem;
    height: 8.25rem;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a0aec0;
  }
.local-buisness-edit-del{
    display: flex;
    // width: 300px;
    justify-content: flex-end;
}
.local-business-category{
    display: flex;
    justify-content: center;
}
.spinner-1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.heading-category{
    margin-bottom: 10px;
    margin-top: 10px;
}
.spinner-2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44vh;
}
.gap-div{
    padding-left: 5px;
    padding-right: 5px;

}
.gap-div-1{
    // padding-left: 5px;
    padding-right: 5px;

}
.local-buisness-table-no-data {
    width: 100%;
    height: 80vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.comma-style{
    padding-right: 5px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 96px;
    font-weight: 500;
}
.dollar-div{
    padding-left: 2px;
}
.table-no-data-1 {
    width: 100%;
    height: 60vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

  }
.inline-span{
    padding-right: 3px;
}
.marquee-container {
    overflow-x: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 72% !important;
}
.marquee {
    flex: 0 0 auto;
    min-width: 192% !important;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
    animation-play-state: var(--play);
    animation-delay: var(--delay);
    animation-direction: var(--direction);
}