.pdf-wrap-close {
  position: absolute;
  top: 30px;
  right: 25px;
  z-index: 500;
  border-radius: 50%;
  height: 25px;
  width: 25px;

  background-color: rgba(138, 173, 208, 0.358);
}
.viewer-wrap {
  height: 100%;
  text-align: center;
  width: 100%;
}
