.user-add-button-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.guest-user-delete-btn-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
}
.guest-user-delete-text {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
  font-weight: 500;
  line-height: 20px;
  color: var(--GENERIC_SIDER_COLOR);
}
