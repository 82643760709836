.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0 !important;
  color: var(--GENERIC_SIDER_COLOR);
  font-weight: 500;
  line-height: 20px;
  font-feature-settings: 'tnum', 'tnum';
  display: flex;
  padding: 10px;
  height: 100%;
  .ant-tabs-nav {
    height: 60px;
    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      width: 8.625rem;
      margin: 0 0 0 0;
      font-size: 12px;
    }
    .user-tab-btn-div {
      height: 2.5rem;
      width: 8.188rem;
    }
  }
  .ant-tabs-content {
    width: 100%;
    height: 100%;
  }
}
.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid var(--GENERIC_PRIMARY_THEME_BLUE) !important;
  z-index: 2;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--GENERIC_PRIMARY_THEME_BLUE);
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab:hover {
  color: var(--GENERIC_PRIMARY_THEME_BLUE);
}
.user-tab-extra-actions {
  width: auto;
  flex-direction: row;
  display: flex;
}
