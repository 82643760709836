.generic-input {
  width: 350px;
  text-align: center;
  border: 1px solid var(--GENERIC_BORDER) !important;
  border-radius: 50px;
  background-color: #ffffff !important;
  height: 2.813rem;
  line-height: 15px;
  font-weight: 500;
  box-shadow: 0 0 0 0 !important;
}
.generic-form-input {
  width: 100%;
  border: 1px solid var(--GENERIC_BORDER) !important;
  border-radius: 50px;
  background-color: #ffffff !important;
  height: 2.813rem;
  line-height: 15px;
  font-weight: 500;
  box-shadow: 0 0 0 0 !important;
}
.generic-form-text-area {
  width: 100%;
  border: 1px solid var(--GENERIC_BORDER) !important;
  border-radius: 15px;
  background-color: #ffffff !important;
  height: 2.813rem;
  line-height: 15px;
  font-weight: 500;
  box-shadow: 0 0 0 0 !important;
}
.ant-modal-wrap {
  background: rgba(8, 12, 203, 0.5);
}
.generic-modal {
  // margin-top: 7%;
  .modal-form-item {
    margin-bottom: 15px !important;
  }
  .ant-form-item {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    vertical-align: top;
    margin-bottom: auto;
    transition: margin-bottom 0.3s 0.017s linear;
  }
  .ant-form-item-explain-error {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    color: var(--GENERIC_ERROR_COLOR);
    text-align: initial;
    font-style: normal;
    font-size: 12px;
    font-family: 'RalewayBold';
    src: url('./theme/fonts/Raleway-VariableFont_wght.ttf');
    font-style: normal;
    font-weight: 500;
  }
  .ant-modal-content {
    background-color: #fff;
    border-radius: 20px;
    // height: 30px;
    pointer-events: auto;
  }
  .ant-modal-header {
    padding: 16px 24px;
    color: var(--GENERIC_TEXT_COLOR_BLACK);
    background: var(--GENERIC_MODAL_HEADER_BG);
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    height: 3.688rem;
    .ant-modal-title {
      margin: 0;
      color: var(--GENERIC_TEXT_COLOR_BLACK);
      font-weight: 700;
      font-size: 20px;
      // line-height: 24px;
    }
  }
  .ant-modal-body {
    padding: 30px;
  }
  .modal-btn-row {
    width: 100%;
    display: flex;
    justify-content: center;
    .modal-btn-col {
      margin: 0 7px;
    }
  }
  .ant-modal-footer {
    border-top: none;
    padding: 0;
  }
}

.ant-form-item-explain-error {
  width: 359px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--GENERIC_ERROR_COLOR);
  text-align: CENTER;
  font-style: normal;
  font-size: 12px;
  font-family: 'RalewayBold';
  src: url('./theme/fonts/Raleway-VariableFont_wght.ttf');
  font-style: normal;
  font-weight: 500;
}
.generic-button {
  background: var(--GENERIC_BUTTON_BG) !important;
  border-radius: 50px;
  height: 2.813rem;
  border: 0 !important;
  text-transform: uppercase;
  width: 100%;
  line-height: 15px;
  color: #ffffff !important;
}

.generic-cancel-button {
  background: #a0aec0 !important;
  border-radius: 50px;
  height: 2.813rem;
  border: 0 !important;
  text-transform: uppercase;
  width: 100%;
  line-height: 15px;
  color: #ffffff !important;
}
.generic-tab-button {
  background: var(--GENERIC_BUTTON_BG) !important;
  border-radius: 50px;
  height: 2.5rem;
  border: 0 !important;
  text-transform: uppercase;
  width: 100%;
  line-height: 15px;
  color: #ffffff !important;
  margin-left: 10px;
}
.user-generic-tab-button {
  background: var(--GENERIC_BUTTON_BG) !important;
  border-radius: 50px;
  height: 2.5rem;
  border: 0 !important;
  text-transform: uppercase;
  width: 100%;
  line-height: 15px;
  color: #ffffff !important;
  margin-left: 0px;
}
//commom overriding
.ant-table-thead > tr > th {
  color: var(--GENERIC_TEXT_COLOR_BLACK);
  font-weight: 500;
  text-align: left;
  background: #e2e8f0;
  border-bottom: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--GENERIC_BORDER_COLOR);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--GENERIC_SIDER_COLOR);
}

.th.ant-table-cell::before {
  background-color: var(--GENERIC_PRIMARY_THEME_BLUE);
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
}
.ant-table-pagination.ant-pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  height: 3rem;
  .ant-pagination-item-active a {
    width: 100%;
    height: 100%;
    color: #ffffff !important;
    background-color: var(--GENERIC_PRIMARY_THEME_BLUE);
    // border-radius: 5px;
    border-radius: 0;
  }
  .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: #718096;

    transition: none;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border: none;
  }
}
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  background-color: var(--GENERIC_BORDER);
  color: #718096 !important;
  border: none;
  // border-radius: 5px;
  border-radius: 0px;
  outline: none;
  transition: all 0.3s;
  margin-left: 10px;
}
.ant-pagination-prev .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  background-color: var(--GENERIC_BORDER);
  color: #718096 !important;
  border: none;
  border-radius: 0px;
  // border-radius: 5px;
  margin-right: 10px;
}
.ant-pagination-item {
  margin-right: 0;
  background-color: var(--GENERIC_BORDER);
  color: #718096 !important;
  border: none;
  border-radius: 0px;
  // border-radius: 5px;
}
/* for removing chrome autofill color*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 0 !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--GENERIC_TEXT_COLOR_BLACK) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.table-content-div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin-top: -15px;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  // border-radius: 20px;
  .ant-table-wrapper {
    width: 100%;
    height: 100%;
  }
}
.facility-table-content-div {
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  padding: 20px;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  // border-radius: 20px;
  .ant-table-wrapper {
    width: 100%;
  }
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: none;
  content: '';
}
//search bar
.search-container {
  margin-left: 10px;
  width: 16.125rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 50px;
  .ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .search-input {
    width: 13.6rem;
    border: none !important;
    border-radius: 50px;
    background-color: #ffffff !important;
    height: 2.5rem;
    line-height: 15px;
    font-weight: 500;
    box-shadow: 0 0 0 0 !important;
    .anticon svg {
      display: inline-block;
      font-size: 20px;
    }
  }
  .search-button {
    background: var(--GENERIC_BUTTON_BG) !important;
    border-radius: 100%;
    height: 2.5rem;
    border: 0 !important;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.display-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-left {
  justify-content: initial;
}
.justify-right {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.justify-initial {
  justify-content: initial;
}
.align-initial {
  align-items: initial;
}
// .facility-details-container {
.ant-pagination {
  margin-top: 2vh;
}
.ant-pagination-item-active a {
  height: 100%;
  background-color: var(--GENERIC_PRIMARY_THEME_BLUE);
  color: #ffffff !important;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #718096;
  transition: none;
}
// }
.no-data-txt {
  line-height: 16px;
  color: #9808cb;
  margin-top: 10px;
}
.no-data-icon {
  height: 80px;
}
// .table-no-data {
//   width: 100%;
//   height: 60vh;
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .ant-table-tbody > tr > td {
//   border-bottom: 1px solid red !important;
//   display: none;
// }

.table-no-data {
  width: 100%;
  height: 60vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-table-thead .ant-table-tbody > tr > td {
    border-bottom: 1px solid red !important;
    display: none;
  }
}

.tab-no-data {
  width: 100%;
  height: 50vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .ant-table-tbody {
//   border-bottom: 1px solid var(--GENERIC_BORDER_COLOR);

// }

.pointer {
  cursor: pointer;
}

.calender {
  background: #ffffff;
  height: 160px;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  // box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}
.month-today {
  position: absolute;
  top: 21px;
  right: 16px;
  border: 1px solid #080ccb;
  border-radius: 4px;
  padding: 2px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.month-today span {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #080ccb;
}
.calender-month-year {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.calender-month-year {
  display: flex;
  justify-content: center;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #09101d;
}
.month-arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #718096;
  padding: 2px 8px;
  height: 22px;
  margin: 0 10px;
  cursor: pointer;
}
.calenderSwiper-container {
  position: relative;
  display: flex;
  align-items: center;
}
.week-date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
  height: 80px;
}
.week-date-container.active {
  background-color: #080ccb;
  border-radius: 100px;
  padding: 5px 0px;
  width: 46px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activeClass-week,
.activeClass-date {
  // font-family: 'Inter';
  // font-style: normal;
  color: #718096;
}
.activeClass-week {
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
}
.activeClass-date {
  font-weight: 600;
  font-size: 14px;
  color: #09101d;
  position: relative;
  background-color: #ffffff;
  padding: 1px;
  border-radius: 100px;
  width: 26px;
}
.week-date-container .week,
.week-date-container .date {
  // font-family: 'Inter';
  // font-style: normal;
  color: #718096;
}
.week-date-container .week {
  font-weight: 400;
  font-size: 11px;
}
.week-date-container .date {
  font-weight: 600;
  font-size: 14px;
}
/* ---------swiper class------------ */
.swiper {
  width: calc(100vw - 12rem - 300px - 1.5rem);
  height: 100%;
}
.swiper-wrapper {
  display: flex;
  width: calc(100vw - 12rem - 300px - 1.5rem) !important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  margin-right: 10px !important;
  display: flex;
  justify-content: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next:after {
  margin-left: 10px !important;
}
.swiper-button-next {
  right: 3px !important;
}
.swiper-button-prev {
  left: 3px !important;
}
.calender-arrow-container {
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #718096;
  padding: 5px 12px;
  max-height: 37px;
}
