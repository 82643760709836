.login-form-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    margin-bottom: 15px;
    vertical-align: top;
    transition: margin-bottom 0.3s 0.017s linear;
  }
  .login-title-div {
    line-height: 24px;
    color: var(--GENERIC_TEXT_COLOR_BLACK);
    margin-bottom: 0.938rem;
    font-size: 20px;
    font-weight: 600;
  }
  .login-form-card {
    width: 26.875rem;
    height: 28.313rem;
    background: var(--GENETRIC_THEME_GRADIENT_BG);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    .awaytogether-logo {
      margin-bottom: 1.563rem;
      margin-top: 1rem;
    }
  }
  input.ant-input {
    padding: 0;
    border: none;
    outline: none;
    text-align: center;
    font-size: 12px;
  }
  .cta-button-div {
    width: 100%;
    display: flex;
    justify-content: center;
    .login-cta {
      margin: auto;
      width: 9.375rem;
    }
    .forgot-cta {
      margin: auto;
      width: 13.563rem;
    }
    .reset-cta {
      margin: auto;
      width: 10.063rem;
    }
  }
  .login-link-txt {
    font-weight: 500;
    line-height: 15px;
    color: var(--GENERIC_PRIMARY_THEME_BLUE);
    font-style: normal;
    font-size: 12px;
  }
  .forgot-title-txt-div {
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: var(--GENERIC_DESCRIPTION_TXT_COLOR);
    margin-bottom: 14px;
  }
}
