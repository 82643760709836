.diningDetails-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 1rem;
}
.diningDetails .facility-details-heading-back-col {
  align-items: flex-start !important;
}
.restImage-div,
.restImage-noImage-div {
  min-width: 60px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.restImage-diningPage {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}
.restImage-noImage-div {
  background: #bdcde3;
}
.calender-content-div {
  margin-top: 2rem;
}

// meal card css

.perDay-mealCard {
  width: 100%;
  height: 85px;
  // background: #e2e8f0;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px;
  cursor: pointer;
}
.card-wrap-heading {
  font-weight: 700;
  color: #09101d;
  display: flex;
  margin-top: 2rem;
}
.mealCard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.1rem;
  width: 100%;
}
.mealdate-exclusive .ant-spin-dot-item {
  background-color: #ffffff;
}
.mealCard-content .mealName {
  font-weight: 700;
  color: #09101d;
  width: 100%;
  padding-right: 5px;
  text-align: start;
}
.mealCard-content .mealItemNumb {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #718096;
}
.mealItemNumb {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.mealCard-content .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2px;
  position: relative;
}

// dining item modal
.mealItem-parent-div .spinner {
  margin-top: 0px;
}
.modalHeading-underline {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
  padding-top: 1rem;
  margin-bottom: 1rem;
  display: none;
}
.noDinint-items-modal {
  display: flex;
  justify-content: center;
}

.mealdate-regular {
  flex-basis: 50%;
  padding: 0 0 1rem;
  width: 100%;
}
.regular-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2d3748;
}
.mealItem-regular-border,
.mealItem-exclusive-border {
  width: 100%;
  height: 1px;
  padding-bottom: 1rem;
}
.mealItem-regular-border {
  border-top: 1px solid rgba(113, 128, 150, 0.15);
}
.mealItem-exclusive-border {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.mealdate-exclusive {
  flex-basis: 50%;
  background-color: #080ccb;
  padding: 0 2rem 1rem;
  margin-left: -2rem;
  margin-right: -2rem;
}
.exclusive-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.items-parent-wrap {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding-top: 1rem;
}
.item-progress-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-top: 3px;
}
.progress-dot {
  width: 10px;
  height: 11px;
  border-radius: 17px;
}
.mealdate-regular .progress-dot {
  background-color: #080ccb;
}
.mealdate-exclusive .progress-dot {
  background-color: #ffffff;
}
.item-progressbar {
  width: 1px;
  height: 90%;
  border-left: 1px dashed rgba(113, 128, 150, 0.15);
}
.mealdate-regular .item-progressbar {
  border-left: 1px dashed rgba(113, 128, 150, 0.15);
}
.mealdate-exclusive .item-progressbar {
  border-left: 1px dashed rgba(255, 255, 255, 0.4);
}
.hide-progressbar {
  display: none;
}
.item-details-parent {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 1rem;
}
.mealdate-regular .item-details-parent {
  border-bottom: 1px dashed rgba(113, 128, 150, 0.15);
}
.mealdate-regular .item-details-parent:last-child {
  border-bottom: none;
}
.mealdate-exclusive .item-details-parent {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
}
.mealdate-exclusive .item-details-parent:last-child {
  border-bottom: none;
}
.item-detail-parent:last-child {
  border-bottom: transparent;
}
.item-name-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  flex-basis: 80%;
  width: 100%;
}
.item-price {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-basis: 20%;
}
.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.mealdate-regular .title {
  color: #09101d;
}
.mealdate-exclusive .title {
  color: #ffffff;
}
.description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.mealdate-regular .description {
  color: #718096;
}
.description {
  color: #ffffff;
}
.price {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}
.mealdate-regular .price {
  color: #080ccb;
}
.mealdate-exclusive .price {
  color: #ffffff;
}
.modalHeadingRight {
  width: 50%;
  flex-basis: 50%;
  background-color: #080ccb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
  border-top-right-radius: 19px;
}
.repeatList-div {
  width: 300px;
  min-width: 300px;
  min-height: 60vh;
  height: fit-content;
  background: #ffffff;
  border-radius: 16px;
  padding: 25px 16px 16px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.repeatList-heading {
  height: 30px;
  width: 100%;
}
.repeatList-underline {
  margin-top: 1rem;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
}
.no-dining-meals-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.no-dining-meals-text.exclusive {
  color: #ffffff !important;
}

.dining-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 1.5rem;
}
.accordion-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  gap: 1.5rem;
}
.viewMenu-btn-separator {
  border-right: 2px solid rgba(113,128,150,.15);
  height: 20px;
  width: 1px;
}
.menu-container{
  position: relative;
}

.viewMenu-btn {
  color: #080ccb;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
}
.menu-drop-down{
  position: absolute;
  background: white;
  z-index: 100;
  width: 200px;
  height: fit-content;
  max-height: 400px;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(0,0,0,.05);
  padding: 20px 20px 10px;
  overflow-y: scroll;
  
}
.background-overlay{
    background-color: transparent;
    bottom: 0;
    height: 100vh;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 5;
}
.menu-file-row {
 
  background-color: rgba(113,128,150,.15);
  border-radius: 8px;
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}
.menu-image{
  max-height: 100%;
  max-Width: 100%;
  object-fit: cover;
  border-Radius: 5px;
}
.menu-file-name{ 
height: 60px;
display: flex;
align-items: center;
overflow: hidden;
width: 55%;
white-space: nowrap;
text-transform: capitalize;
}
.menu-drop-down-content{
  display: flex;
  gap: 5px;
  align-items: flex-start;
}
.facility-detail-header-container{
  display: flex;
   gap: 10px;
}
.menu-dropdown-inner-container{
  height: fit-content;
  overflow: auto;
}

@media screen and (max-width: 1660px) {
  .dining-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}
@media screen and (max-width: 1320px) {
  .dining-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 960px) {
  .dining-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
