.dash-number-card-container {
  height: fit-content;
  // width: 100%;
  padding: 20px;
  overflow-y: auto;
  margin-left: 22px;
}
.dash-board-container {
  height: 75vh;
  width: 100%;
  //   padding: 0 20px;
  overflow: auto;
  .chart-container-row {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    .chart-div {
      border-radius: 10px;
      background-color: #ffffff;
      height: 48vh;
      width: 48%;
      overflow: hidden;
    }
  }
}
.chart-div-1 {
  border-radius: 10px;
  background-color: #ffffff;
  height: 48vh;
  width: 48%;
  // overflow: hidden;
}
.dashboard-img-1 {
  background: #080ccb;
  width: 350px;
  border-radius: 20px;
  position: relative;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  height: 115px;
  background-repeat: no-repeat;
  background-position: right;
}
.dashboard-img-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}
.div-font-1 {
  font-weight: 800;
  font-size: 32px;
  align-items: center;
  display: flex;
  padding: 10px;
}
.div-font-2 {
  // font-weight: 800;
  // font-size: 32px;
  display: flex;
  // padding-left: 10px;
  // margin-left: 5px;
  align-items: center;
}
.dashboard-img-3 {
  position: absolute;
  // bottom: 0;
  top: 0;
  right: 0;
}
.background-img-1 {
  background-image: url('../../../public/assets/dashboard-img.svg');
  background-size: contain;
}
.background-img-2 {
  background-image: url('../../../public/assets/dashboard-img-2.svg');
  background-size: contain;
}
.background-img-3 {
  background-image: url('../../../public/assets/dashboard-img-3.svg');
  background-size: contain;
}
.background-img-4 {
  background-image: url('../../../public/assets/dashboard-img-4.svg');
  background-size: contain;
}
.no-of-facilities-onboarded-by-month {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  color: #718096;
  font-weight: 700;
  padding-top: 20px;
}
.spinner {
  // width:100vw;
  // height: 100vh;
  margin-top: 100px;
  // background-color: #ffffff;
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #ffbd59;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #080ccb;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
