.change-password-page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .change-password-card {
    width: 26.875rem;
    height: 22.438rem;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    .ant-input-affix-wrapper > input.ant-input {
      padding: 0;
      border: none;
      outline: none;
      text-align: center;
    }
    .generic-change-form-input {
      width: 21.875rem;
      border: 1px solid var(--GENERIC_BORDER) !important;
      border-radius: 50px;
      background-color: #ffffff !important;
      height: 2.813rem;
      line-height: 15px;
      font-weight: 500;
      box-shadow: 0 0 0 0 !important;
    }
    .ant-form-item {
      box-sizing: border-box;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum';
      vertical-align: top;
      margin-bottom: 15px;
      transition: margin-bottom 0.3s 0.017s linear;
    }
    .ant-form-item-explain-error {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
      color: var(--GENERIC_ERROR_COLOR);
      text-align: center;
      font-style: normal;
      font-size: 12px;
      font-family: 'RalewayBold';
      src: url('../../theme/fonts/Raleway-VariableFont_wght.ttf');
      font-style: normal;
      font-weight: 200;
    }
    .change-password-txt-heading {
      line-height: 24px;
      text-align: center;
      color: #09101d;
      margin-top: 2.5rem;
      margin-bottom: 1.875rem;
    }
    .change-password-cta-div {
      width: 11.563rem;
      margin: auto;
      margin-bottom: 2.5rem;
    }
  }
}
