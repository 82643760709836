.event-head {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.event-details-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}
.event-left-container,
.event-right-container {
  display: flex;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.event-left-container {
  gap: 2rem;
  min-height: 300px;
  padding: 2rem;
  align-items: flex-start;
  width: 100%;
}
.event-right-container {
  flex-direction: column;
  width: 350px;
  min-width: 350px;
  height: 500px;
  padding: 2rem 1rem;
}

.event-details-div {
  width: 100%;
}
.event-heading-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.event-details-div .heading {
  display: flex;
  font-weight: 700;
  font-size: 16px;
  color: #09101d;
}
.event-time-date-div {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  flex-direction: column;
  color:#718096; 
}
.event-location-div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color:#718096;
}
.event-location-div .location {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
}
.event-details-header .ant-page-header-heading-title {
  font-size: 16px;
  color: #080ccb ;
}
.amenities-page .ant-page-header{
  padding: 16px 0px !important;

}

.event-details-separator {
  height: 2px;
  width: 100%;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.about-event-div,
.event-attachment-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}
.about-event-div {
  margin-bottom: 1.5rem;
}
.about-event-div .about-event,
.event-attachment-div .attachment {
  
  font-weight: 700;
  font-size: 14px;
  color: #09101d;
}
.about-event-div .event-description {
 
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #718096;
  text-align: start;
}

.posterImage-div {
  background-color: black;
  border-radius: 12px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.posterImage {
  border-radius: 12px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.event-right-container .users-head-div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.guests-interested-heading {
  
  font-weight: 700;
  font-size: 16px;
  color: #09101d;
}
.user-details-separator {
  height: 2px;
  width: 100%;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.guest-card-parent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  max-height: 400px;
  overflow-y: auto;
}
.guest-card-parent::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  background-color: #080ccb;
}

.event-user-card {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}
.event-user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start ;
  gap: 5px;
  width: 100%;
}
.guest-div-class {
  width: 40px !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 15%;
}
.guest-image-class {
  width: 40px !important;
  height: 40px;
  border-radius: 40px;
  object-fit: cover;
}
.guest-noImage-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 15%;
}
.event-user-details .name {
  height: 15px;
  overflow-y: auto;
  width: 100%;
  font-weight: 700;
  color: #09101d;
  text-align: start;
  word-break: break-all;
  overflow-x: hidden;

}
.event-user-details .name::-webkit-scrollbar {
  display: none;
}
.event-user-details .name {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.event-user-details .email {
 
  font-weight: 400;
  font-size: 11px;
  color: #718096;
  height: 15px;
  overflow-y: auto;
  text-align: start;
  overflow-x: hidden;
  word-break: break-all;
 
}
.event-user-details .email::-webkit-scrollbar {
  display: none;
}
.event-user-details .email {
  -ms-overflow-style: none;
  scrollbar-width: none;
  color:#718096;
}
.guest-card-separator {
  width: 100%;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
}

.guestUser-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}

.no-guests-yet {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #a0aec0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.enlarge-event-image {
  width: 100vw;
  overflow: hidden;
  object-fit: contain;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;


}
.enlarge-event-image-hide {
  display: none;
}
.enlarge-event-image-close {
  top: 7px;
  right: 24px;
  z-index: 100011;
  cursor: pointer;
}
.enlarge-image-div {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 100000;
}
.enlarge-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.previewClose-button{
background-color: #fff;
border-radius: 50%;
margin: 5px;
position: absolute;
padding: 5px;
width: 1.5rem;
}